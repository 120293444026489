import './ImageScroller.scss'
import Scroller from 'modules/scroller/Scroller'

class ImageScroller {
  constructor (image, scale = 1.1, direction = 1) {
    this.image = image
    this.scale = scale
    this.direction = direction

    this.image.onload = this.onImageLoad
    this.prepareScroller()
  }

  prepareScroller = () => {
    this.el = document.createElement('div')
    this.el.className = 'image-scroller'

    this.inner = document.createElement('div')
    this.inner.className = 'image-scroller__inner'

    const parent = this.image.parentNode
    parent.style.backgroundImage = ''
    parent.appendChild(this.el)

    this.el.appendChild(this.inner)
    this.inner.appendChild(this.image)

    this.inner.style.backgroundImage = `url(${this.image.src})`

    this.scroller = new Scroller(this.inner, this.applyTransform)
  }

  applyTransform = (image, offset) => {
    const maxOffset = (this.scale - 1) * 100 * this.direction
    image.style.transform = `scale(${this.scale}) translateY(${-offset * maxOffset}%)`
  }

  onImageLoad = () => {
    this.resize()
  }

  flush () {
    this.scroller && this.scroller.flush()
    this.scroller = null

    this.image.onload = null
    this.image = null
    this.inner = null
    this.el = null
  }

  resize = () => {
    this.scroller && this.scroller.resize()
  }
}

export default ImageScroller
