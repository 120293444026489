// Basic browser detect
const iphone = !!navigator.platform && /iPhone|iPod/.test(navigator.platform)
const ipad = !!navigator.platform && /iPad/.test(navigator.platform)
const ios = iphone || ipad

const android = /(android)/i.test(navigator.userAgent)

export default {
  android,
  iphone,
  ipad,
  ios
}
