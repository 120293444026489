import TextAnimator from 'modules/text-animator/TextAnimator'
import FolioPage from 'navigation/FolioPage'
import map from 'lodash/map'
import each from 'lodash/each'
import anime from 'animejs'

import scroll from 'navigation/scroll'

class About extends FolioPage {
  prepare () {
    super.prepare(...arguments)
    this.title = new TextAnimator(this.el.querySelector('h2.title'), { letterByLetter: true, offset: 140 })
  }

  show (callback, previousPage) {
    this.resize()

    const blocks = this.el.querySelectorAll('.presentation, .capabilities, .partners, .links, .socials')
    const targets = [blocks[0], blocks[1], [blocks[2], blocks[3]], blocks[4]]
    each(blocks, bloc => (bloc.style.opacity = 0))

    super.show(() => {
      this.title.animateIn(0).then(() => {
        return anime({
          targets,
          opacity: [0, 1],
          translateY: [50, 0],
          duration: 500,
          delay: (el, i) => (100 * i) + 100,
          easing: 'easeOutQuad',
          complete: callback
        }).finished
      })
    }, previousPage)
  }

  flush () {
    this.title.flush()
    this.title = null
    super.flush()
  }

  resize = () => {
    super.resize()
  }
}

About.pageName = 'About'

export default About
