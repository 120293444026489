import Page from './Page'
import anime from 'animejs'
import Header from 'modules/header/Header'
import promise from 'helpers/promise'
import resize from 'helpers/resize'

import map from 'lodash/map'
import each from 'lodash/each'

class FolioPage extends Page {
  constructor (el, options = {}) {
    super(...arguments)
    this.panel = options.panel
  }

  showPanel () {
    FolioPage.prototype.resize.call(this)

    const targets = {
      translateY: -100
    }

    return anime({
      targets,
      translateY: [-100, 0],
      easing: 'easeInSine',
      duration: 400,
      update: () => this.updatePanel(targets.translateY)
    }).finished
  }

  updatePanel = value => (this.panel.style.transform = `translate(-50%, -50%) rotate(45deg) translateY(${value}%)`)

  hidePanel () {
    const targets = {
      translateY: 0
    }

    return anime({
      targets,
      translateY: [0, 100],
      easing: 'easeOutSine',
      duration: 500,
      update: () => this.updatePanel(targets.translateY),
      complete: () => {
        this.resetPanel()
      }
    }).finished
  }

  resetPanel () {
    this.updatePanel(-100)
  }

  prepare (previousPage) {
    this.header = new Header(this.el.querySelector('header'), this.pageName())
  }

  show (callback, previousPage, no) {
    this.header && this.header.show(previousPage)

    return this.hidePanel()
      // .then(() => this.header && this.header.show(previousPage))
      .then(() => promise.wait(200))
      .then(() => {
        callback && callback()
        this.resize()
      })
  }

  askShow = previousPage => new Promise((resolve, reject) => {
    this.preloadImages()
      .then(() => this.onImageLoad())
      .then(() => promise.wait((previousPage && !this.imagesToLoad) ? 0 : 200))
      .then(() => {
        this.show(() => {
          this.state.shown = true
          resolve()
        }, previousPage)
      })
  })

  preloadImages () {
    if (!this.imagesToLoad || !this.imagesToLoad.length) return Promise.resolve()
    document.body.style.cursor = 'wait'
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = (img) => new Promise((resolve) => {
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => resolve()
  })

  onImageLoad () {
    document.body.style.cursor = 'inherit'
    this.resize()
  }

  hide (callback, nextPage) {
    this.header && this.header.hide()
    return this.showPanel()
      .then(() => callback && callback())
  }

  flush () {
    const buttons = this.el.querySelectorAll('.menu-button')
    each(buttons, (b) => b.removeEventListener('click', this.onMenuClick))
    this.header && this.header.flush()
    super.flush()
  }

  resize () {
    super.resize()

    const size = Math.ceil(Math.sqrt(resize.width() * resize.width() + resize.height() * resize.height()))
    this.panel.style.width = size + 'px'
    this.panel.style.height = size + 'px'
  }
}

export default FolioPage
