import FolioPage from 'navigation/FolioPage'
import map from 'lodash/map'
import each from 'lodash/each'

import Reveal from 'modules/reveal/Reveal'
import ImageScroller from 'modules/image-scroller/ImageScroller'
import TextAnimator from 'modules/text-animator/TextAnimator'

import promise from 'helpers/promise'
import anime from 'animejs'

class Work extends FolioPage {
  prepare () {
    const images = this.el.querySelectorAll('.cover img, .block-image.parralax img')
    this.scrollers = map(images, image => new ImageScroller(image))

    this.cover = this.el.querySelector('.cover')

    const introduction = this.el.querySelector('.introduction')
    const scaleDelay = Math.max(1.3, introduction.innerText.length / 100)
    this.textAnimator = new TextAnimator(introduction, {
      wordByWord: true,
      easing: 'easeOutQuart',
      letterByLetter: false,
      scaleDelay,
      duration: 500
    })

    super.prepare()
  }

  show (callback, nextPage) {
    super.show(() => {
      this.textAnimator.animateIn()
        .then(() => promise.wait(100))
        .then(() => this.animateCover())
        .then(() => this.el.classList.add('show-page'))
        .then(() => callback())
    }, nextPage)
  }

  animateCover () {
    return anime({
      targets: this.cover,
      opacity: [0, 1],
      translateY: ['25%', 0],
      duration: 800,
      easing: 'easeOutQuart'
    }).finished
  }

  onImageLoad (callback, previousPage) {
    super.onImageLoad(...arguments)
    this.reveals = map(this.el.querySelectorAll('.reveal-block'), el => new Reveal(el))
  }

  flush () {
    this.textAnimator && this.textAnimator.flush()
    this.reveals && each(this.reveals, revealBlock => revealBlock.flush())
    each(this.scrollers, scroller => scroller.flush())
    super.flush()
  }

  resize = () => {
    super.resize()
    this.reveals && each(this.reveals, revealBlock => revealBlock.resize())
    each(this.scrollers, scroller => scroller.resize())
  }
}

Work.pageName = 'Work'

export default Work
