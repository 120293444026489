import Config from 'core/Config'
import Navigo from 'navigo'

const buildRoot = lang => Config.baseurl + '/'
// const buildRoot = lang => Config.baseurl + '/' + lang + '/'

const router = new Navigo(buildRoot(Config.lang))
router.path = () => window.location.href
router.uri = () => window.location.pathname

window.updateLang = (lang) => {
  Config.lang = lang
  router.root = buildRoot(Config.lang)
  router.lastRouteResolved().url = ''
}

export default router
