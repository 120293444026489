/* global gtag */
import PageManager from './PageManager'
import Config from 'core/Config'
import router from 'navigation/router'
import scroll from './scroll'

class FolioPageManager extends PageManager {
  removePage () {
    super.removePage()
    scroll.reset()
  }

  initializePage () {
    super.initializePage(...arguments)
    document.scrollingElement.scrollTop = 0
  }

  pageLoaded (pathName, PageClass, event) {
    super.pageLoaded(...arguments)

    gtag && gtag('config', Config.trackingId, {
      'page_path': router.uri()
    })
  }
}

export default FolioPageManager
