import TextAnimator from 'modules/text-animator/TextAnimator'
import FolioPage from 'navigation/FolioPage'
import map from 'lodash/map'
import each from 'lodash/each'
import anime from 'animejs'

import resize from 'helpers/resize'
import scroll from 'navigation/scroll'
import { clamp, map as mapValues } from '@internet/maths'

import WorkScroller from './scroller/WorkScroller'

class Works extends FolioPage {
  prepare () {
    super.prepare(...arguments)

    this.header.enableScrollTitle()
    this.intro = this.el.querySelector('.presentation')

    const blocks = this.el.querySelectorAll('.work')
    this.scrollers = map(blocks, (block, i) => new WorkScroller(block, i !== blocks.length - 1))

    this.animators = []
    this.animators.push(new TextAnimator(this.el.querySelector('.presentation .job'), { letterByLetter: true, delayScale: 1.5 }))
    this.animators.push(new TextAnimator(this.el.querySelector('.presentation .name'), { letterByLetter: false, offset: 140 }))
    this.animators.push(new TextAnimator(this.el.querySelector('.presentation .introduction'), { letterByLetter: false, offset: -this.animators[1].offset }))

    scroll.instance().on(this.scroll)
  }

  scroll = () => {
    const { scrollTop } = scroll
    const { easeOutQuad } = anime.easings
    const offset = clamp(mapValues(scrollTop(), 0, resize.height() / 3, 1, 0), 0, 1)
    this.intro.style.opacity = easeOutQuad(offset)
  }

  show (callback, previousPage) {
    this.resize()

    const blocks = [...this.el.querySelectorAll('.work')].slice(0, 2)
    each(blocks, bloc => (bloc.style.opacity = 0))

    super.show(() => {
      const subtitleDelay = 250

      this.animators[0].animateIn()
      this.animators[1].animateIn(subtitleDelay)
      this.animators[2].animateIn(subtitleDelay).then(() => {
        return anime({
          targets: blocks,
          opacity: [0, 1],
          translateY: ['25%', 0],
          duration: 800,
          delay: (el, i) => 100 * i,
          easing: 'easeOutQuart',
          complete: callback
        }).finished
      })
    }, previousPage)
  }

  flush () {
    each(this.scrollers, scroller => scroller.flush())
    each(this.animators, animator => animator.flush())
    scroll.instance().off(this.scroll)
    super.flush()
  }

  resize = () => {
    super.resize()
    each(this.scrollers, scroller => scroller.resize())
  }
}

Works.pageName = 'Works'

export default Works
