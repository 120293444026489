
import resize from 'helpers/resize'
import scroll from 'navigation/scroll'
import TextAnimator from 'modules/text-animator/TextAnimator'

import style from 'core/style'
import bowser from 'bowser'

import anime from 'animejs'
import each from 'lodash/each'
import { clamp, map } from '@internet/maths'

const mapClamp = (value, start1, stop1, start2, stop2) => clamp(map(value, start1, stop1, start2, stop2), start2, stop2)

const maxScrollHeight = parseInt(style.workBlockScrollHeight) / 100

export default class WorkScroller {
  constructor (el, performOutAnimation = true) {
    if (!bowser.desktop) return

    this.el = el
    this.child = this.el.firstElementChild
    this.inner = this.el.querySelector('.work-inner')
    this.cover = this.el.querySelector('.cover')
    this.caption = this.el.querySelector('.caption')
    this.video = this.el.querySelector('video')

    this.title = new TextAnimator(this.el.querySelector('.title'))

    const description = this.el.querySelector('.description')
    if (description) this.description = new TextAnimator(description, { letterByLetter: false })

    this.performOutAnimation = performOutAnimation
    this.horizontalBorders = [...this.el.querySelectorAll('.work-border-right, .work-border-left')]
    this.verticalBorders = [...this.el.querySelectorAll('.work-border-top, .work-border-bottom')]

    if (this.video) {
      this.video.muted = true
      this.video.playsInline = true
    }

    this.resize()
    resize.add(this)
    scroll.reset()
    scroll.instance().on(this.scroll)
  }

  step = 0

  scroll = (force = false) => {
    const { scrollTop } = scroll

    const offset = -(this.bounds.top - scrollTop()) / resize.height()
    // offset = Math.max(0, -offset)

    const visible = (scrollTop() + resize.height()) > this.bounds.top && scrollTop() < this.bounds.bottom

    if (offset === this.offset && visible === this.visible && !visible && !force) return

    this.offset = offset
    this.visible = visible

    window.requestAnimationFrame(() => this.transform(offset, visible))
  }

  opened = false
  passed = false

  transform = (offset, visible) => {
    const endOffset = 0.75
    const baseOffset = 0.5

    const translateOffset = clamp(offset, 0, this.sizes.maxOffset)
    const translateEndOffset = mapClamp(offset, this.sizes.maxOffset, this.sizes.maxOffset + endOffset, 0, 1)
    const topOffset = mapClamp(offset + baseOffset, 0, baseOffset, 0, 1)

    const scaleOffset = mapClamp(offset + baseOffset, 0, 0.75, 0, 1)
    const borderOffset = mapClamp(offset + baseOffset, 0, maxScrollHeight, 0, 1)

    const opened = topOffset >= 0.6
    const passed = translateEndOffset > 0.5 && opened

    if (opened !== this.opened) {
      this.el.classList.toggle('opened', opened)
      const method = opened ? 'animateIn' : 'animateOut'
      this.title[method](0, !opened)
      this.description && this.description[method](0, !opened)
      this.opened = opened

      if (opened) this.onBlockEnter()
      else this.onBlockLeave()
    }

    if (passed !== this.passed) {
      this.passed = passed
      if (passed) this.onBlockLeave()
      else this.onBlockBack()
    }

    const { easeInQuad, easeOutQuad, easeInSine, easeOutSine } = anime.easings

    let translateY = resize.height() * translateOffset * 0.9 // Follow scroll
    const translateEndY = (resize.height() * 0.1) * easeInSine(translateEndOffset) // End of scroll
    const marginY = this.sizes.blockTop * easeInQuad(topOffset) // Center in page

    if (this.performOutAnimation) {
      this.child.style.opacity = 1 - translateEndOffset
      translateY += translateEndY
    }

    this.child.style.transform = `translateY(${translateY + marginY}px)`

    const interpolation = easeOutQuad(scaleOffset)
    const scale = this.sizes.minScale + (1 - this.sizes.minScale) * interpolation
    this.inner.style.transform = `scale(${scale})`

    const captionTranslateY = -(1 - scale) / 2
    this.caption.style.transform = `translateY(${captionTranslateY * 100}%)`
    const borderInterpolation = easeOutSine(borderOffset)

    each(this.verticalBorders, border => (border.style.transform = `scaleY(${1 - borderInterpolation})`))
    each(this.horizontalBorders, border => (border.style.transform = `scaleX(${1 - borderInterpolation})`))
  }

  onBlockEnter = () => {
    if (!this.video) return
    this.video.currentTime = 0
    this.video.play()
  }

  onBlockBack = () => {
    if (!this.video) return
    this.video.play()
  }

  onBlockLeave = () => {
    if (!this.video) return
    this.video.pause()
  }

  flush () {
    if (!bowser.desktop) return

    this.el = null
    this.bounds = null

    this.title.flush()
    this.title = null
    this.description && this.description.flush()
    this.description = null

    resize.remove(this)
    scroll.instance().off(this.scroll)
  }

  resize = () => {
    const bounds = this.el && this.el && this.el.getBoundingClientRect()
    if (!bounds || !bounds.height) return

    const blockWidth = parseInt(style.workBlockWidth)
    const blockRatio = parseFloat(style.workBlockRatio)
    const borderScale = 1 + parseFloat(style.workBorderScale)
    const maxWidth = Math.min(parseInt(style.workBlockMaxWidth), resize.width())
    const maxHeight = Math.min(maxWidth / blockRatio, resize.height())

    this.sizes = {}
    this.sizes.blockTop = (resize.height() - maxHeight) / 2
    this.sizes.minScale = (blockWidth * borderScale / maxWidth)
    this.sizes.maxOffset = (bounds.height - (maxHeight + this.sizes.blockTop * 2)) / resize.height()

    this.bounds = {}
    this.bounds.top = bounds.top + scroll.scrollTop()
    this.bounds.bottom = bounds.bottom + scroll.scrollTop()

    this.scroll(true)
  }
}
