
import anime from 'animejs'
import resize from 'helpers/resize'
import scroll from 'navigation/scroll'
import TextAnimator from 'modules/text-animator/TextAnimator'

export default class Header {
  constructor (el, pageName) {
    this.el = el
    this.pageName = pageName
    this.title = new TextAnimator(this.el.querySelector('li.name a'), { letterByLetter: true, delayScale: 1.5 })
    this.lis = this.el.querySelectorAll('li:not(.name)')
  }

  enableScrollTitle () {
    this.scrollEnabled = true
    scroll.instance().on(this.scroll)
  }

  show (currentPage, previousPage) {
    return anime({
      targets: this.lis,
      duration: 600,
      easing: 'easeOutQuad',
      // opacity: [0, 1],
      translateY: [100, 0]
    }).finished
      .then(() => {
        if (this.scrollEnabled || this.pageName === 'About') return
        return this.title.animateIn(0)
      })
  }

  hide () {}

  hidden = true

  scroll = () => {
    const { scrollTop } = scroll
    const hidden = scrollTop() < resize.height() / 4

    if (hidden !== this.hidden) {
      this.hidden = hidden
      if (hidden) this.title.animateOut(0, true)
      else this.title.animateIn(0)
    }
  }

  flush () {
    if (this.title && this.scrollEnabled) scroll.instance().off(this.scroll)
    this.title && this.title.flush()
  }
}
