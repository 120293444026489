
import resize from 'helpers/resize'
import scroll from 'navigation/scroll'

export default class Reveal {
  constructor (el, progress = 0.2, className = 'revealed') {
    this.el = el
    this.progress = progress
    this.className = className

    this.resize()
    scroll.instance().on(this.scroll)
  }

  visible = false

  scroll = () => {
    const { scrollTop } = scroll
    const visible = (this.bounds.top - scrollTop()) < ((1 - this.progress) * resize.height())

    if (visible !== this.visible) {
      this.visible = visible
      if (visible) this.onAppear()
      else this.onDisappear()
    }
  }

  onAppear () {
    this.el.classList.add(this.className)
  }

  onDisappear () {
    this.el.classList.remove(this.className)
  }

  resize = () => {
    const { scrollTop } = scroll
    const { top } = this.el.offsetParent.getBoundingClientRect()
    const { height } = this.el.getBoundingClientRect()

    this.bounds = {}
    this.bounds.top = top + scrollTop() + this.el.offsetTop
    this.bounds.bottom = this.bounds.top + height

    this.scroll()
  }

  flush () {
    scroll.instance().off(this.scroll)
  }
}
