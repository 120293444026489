import './TextAnimator.scss'
import each from 'lodash/each'
import text from 'helpers/text'

import anime from 'animejs'

class TextAnimator {
  constructor (el, {
    offset = 130,
    duration = 400,
    vertical = true,
    scaleDelay = 1.25,
    easing = 'easeOutQuad',
    letterByLetter = true,
    wordByWord = false,
    verticalPadding = 0.05,
    horizontalPadding = 0.05,
    automaticallyHidden = true
  } = {}) {
    this.el = el

    this.easing = easing
    this.offset = offset
    this.vertical = vertical
    this.duration = duration
    this.scaleDelay = scaleDelay
    this.wordByWord = wordByWord
    this.letterByLetter = letterByLetter
    this.verticalPadding = verticalPadding
    this.horizontalPadding = horizontalPadding
    this.automaticallyHidden = automaticallyHidden

    this.prepareText()
  }

  getProperty = () => `translate${this.vertical ? 'Y' : 'X'}`
  getTransform = offset => `${this.getProperty()}(${offset}%)`

  prepareText = () => {
    if (this.letterByLetter) {
      this.el.innerHTML = `<span class='text-animator'>${text.spanify(this.el.innerHTML, true, false)}</span>`
      this.glyphContainers = this.el.querySelectorAll('.word')
      this.glyphs = this.el.querySelectorAll('.letter')
    } else if (this.wordByWord) {
      this.el.innerHTML = `<span class='text-animator'>${text.spanify(this.el.innerHTML, false, true)}</span>`
      this.glyphContainers = this.el.querySelectorAll('.word')
      this.glyphs = this.el.querySelectorAll('.inner-word')
    } else {
      this.el.innerHTML = `<span class='text-animator'><span class='word'><span class='letter'>${this.el.innerHTML}</span></span></span>`
      this.glyphContainers = this.el.querySelectorAll('.word')
      this.glyphs = this.el.querySelectorAll('.letter')
    }

    // // Restore line-height
    // const style = window.getComputedStyle(this.el)
    // const lineHeight = parseFloat(style.lineHeight) / parseFloat(style.fontSize) + 'em'

    each(this.glyphContainers, container => {
      container.style.padding = `${this.verticalPadding}em ${this.horizontalPadding}em`
      container.style.margin = `${-this.verticalPadding}em ${-this.horizontalPadding}em`
      // word.style.lineHeight = 0
    })

    each(this.glyphs, glyph => {
      // letter.style.lineHeight = lineHeight
      if (this.automaticallyHidden) glyph.style.transform = this.getTransform(this.offset)
    })
  }

  animateIn = (globalDelay = 0, invert = false) => {
    const delay = (this.duration * (this.scaleDelay - 1)) / this.glyphs.length
    const sign = invert ? -1 : 1

    return anime({
      targets: this.glyphs,
      [this.getProperty()]: [this.offset * sign, 0],
      easing: this.easing,
      duration: this.duration,
      delay: (el, i) => (i * delay) + globalDelay
    }).finished
  }

  animateOut = (globalDelay = 0, invert = false) => {
    const delay = (this.duration * (this.scaleDelay - 1)) / this.glyphs.length
    const sign = invert ? -1 : 1

    return anime({
      targets: this.glyphs,
      [this.getProperty()]: [0, this.offset * sign],
      easing: this.easing,
      duration: this.duration,
      delay: (el, i) => (i * delay) + globalDelay
    }).finished
  }

  flush () {
    this.el = null
    this.glyphs = null
    this.glyphContainers = null
  }
}

export default TextAnimator
