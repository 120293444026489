import PageManager from 'navigation/FolioPageManager'
import FolioPage from 'navigation/FolioPage'

import Work from 'sections/work/Work'
import About from 'sections/about/About'
import Works from 'sections/works/Works'

import router from 'navigation/router'
import scroll from 'navigation/scroll'

import resize from 'helpers/resize'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    this.listenResize()
  }

  createPageManager () {
    const panel = this.el.querySelector('.panel')

    this.pageManager = new PageManager(document.querySelector('.container'), '.page', {
      '/work/:id': Work,
      '/about': About,
      '': Works,
      '*': FolioPage
    }, { panel })

    router.hooks({
      after: (done) => {
      }
    })
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  }
}

export default Main
